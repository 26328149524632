import { list_functionality } from '@/services/list-functionality/list-functionality'
//Components
import VueTable from '@/common/VueTable/VueTable.vue'
import Actions from '@/common/VueTable/Actions.vue'
import TableHeader from '@/common/TableHeader.vue'
import PlayPause from '@/modules/Timer/components/PlayPause.vue'
import TaskViewDialog from '@/modules/ProjectPreview-Tasks/components/TaskViewDialog/TaskViewDialog.vue'
import TaskTimerBox from '@/common/HoursBox/TaskTimer.vue'
export default {
    name: 'TaskTimer',
    mixins: [list_functionality],
    components: {
        VueTable,
        Actions,
        TableHeader,
        PlayPause,
        TaskViewDialog,
        TaskTimerBox
    },

    data: () => ({
        paths: [{
                text: 'Dashboard',
                disabled: false,
                route: { name: 'default-content' }
            },
            { text: 'Timers', disabled: true, route: null },
            { text: 'Task', disabled: true, route: null }
        ],
        sortList: [
            { title: 'Sort by Client' },
            { title: 'Sort by Task' },
            { title: 'Sort by Services' },
            { title: 'Sort by Time' },
            { title: 'Sort by Date' }
        ],
        headers: [
            { text: 'Assigned To', sortable: false, align: 'left', width: 150 },
            { text: 'Task', sortable: false, align: 'left' },
            { text: 'Projects', sortable: false, align: 'left' },
            {
                text: 'Total Time',
                sortable: false,
                align: 'center',
                width: 240
            }
        ],
        timer_tab: 'task-timers',
        currentTab: 'task-timers',
        task_status: [
            { text: 'All', value: 'all' },
            { text: 'Open', value: 'open' },
            { text: 'Pending', value: 'pending' },
            { text: 'Completed', value: 'completed' },
            { text: 'Urgent', value: 'urgent' }
        ],
        filter_task: 'all',
        task: null,
        view_task_dialog: false
    }),
    watch: {
        filter_task(val) {
            this.filterTask(val)
        }
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.fill_table_via_url(
            `api/timer/tasks?all=true&filter=${this.filter_task}`
        )
    },
    computed: {
        loggeduser() {
            return this.$store.getters.user
        }
    },
    methods: {
        load_more() {
            this.load_more_via_url(
                `api/timer/tasks?all=true&filter=${this.filter_task}`
            )
        },
        can_run_timer(item) {
            let find = item.assigned.find((u) => u.id === this.loggeduser.id)
            return !!find
        },
        handleChangeTab(event) {
            if (this.timer_tab === 'global-timers')
                this.$router.push({ name: 'globalTimer' })
            if (this.timer_tab === 'alarm') this.$router.push({ name: 'alarm' })
        },
        timerEnd(item) {
            if (item.timer.timer_status === 'ongoing') {
                return 'Ongoing'
            }
            if (item.timer.timer_status === 'pause') {
                return 'Paused'
            }
            return '-'
        },
        filterTask(value) {
            this.filter_task = value
            this.resetPagination()
            this.fill_table_via_url(`api/timer/tasks?&all=true&filter=${value}`)
        },
        set_and_view_task(task) {
            this.task = task
            this.view_task_dialog = true
        },
        minimize() {
            this.$router.push({ name: 'default-content' })
        }
    }
}