var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"timer"},[_c('TaskViewDialog',{attrs:{"task":_vm.task},model:{value:(_vm.view_task_dialog),callback:function ($$v) {_vm.view_task_dialog=$$v},expression:"view_task_dialog"}}),_c('VueTable',{key:_vm.componentKey,attrs:{"items":_vm.items,"headers":_vm.headers,"show-row-actions":true,"loading":_vm.loading,"has-expand":"","min-height":"80vh","icon":"mdi-folder-clock-outline","title":"Task Timers","no-more-data":_vm.noMoreData,"show-select":false},on:{"load-more":_vm.load_more},scopedSlots:_vm._u([{key:"header-toolbar",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 text-none",staticStyle:{"border":"1px solid #223a6b"},attrs:{"width":"150","dense":"","text":"","outlined":"","color":"#223A6B"}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"color":"#223a6b"}},[_vm._v(_vm._s(_vm._f("ucwords")(_vm.filter_task)))]),_c('v-spacer'),_c('v-icon',{attrs:{"small":"","right":"","color":"#223A6B"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.task_status),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){_vm.filter_task = item.value}}},[_c('v-list-item-title',{staticClass:"primary--text fw-700"},[_vm._v(" "+_vm._s(item.text)+" ")])],1)}),1)],1),_c('v-btn-toggle',{attrs:{"mandatory":"","dense":"","value":_vm.timer_tab},on:{"change":_vm.handleChangeTab},model:{value:(_vm.timer_tab),callback:function ($$v) {_vm.timer_tab=$$v},expression:"timer_tab"}},[_c('v-btn',{staticClass:"px-5 text-none light--text",attrs:{"dark":"","value":"task-timers","color":"primary"}},[_c('v-icon',{attrs:{"color":"white","left":""}},[_vm._v("mdi-folder-clock-outline")]),_vm._v(" Task Timers ")],1),_c('v-btn',{staticClass:"px-5 text-none primary--text",attrs:{"text":"","value":"global-timers"}},[_c('v-icon',{attrs:{"color":"primary","left":""}},[_vm._v("mdi-account-clock")]),_vm._v(" Global Timers ")],1)],1)]},proxy:true},{key:"row-slot",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.assigned.length > 0)?_c('Avatars',{staticStyle:{"display":"inline-block"},attrs:{"items":item.assigned,"count":1}}):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","large":""}},[_vm._v("mdi-account-off-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Unassigned")])])],1),_c('td',{staticClass:"clickable-td",on:{"click":function($event){return _vm.set_and_view_task(item)}}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("ucwords")(item.title)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("ucwords")(item.title)))])])],1),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm._f("ucwords")(item.project.project_name)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("ucwords")(item.project.project_name)))])])],1),_c('td',{staticClass:"text-center"},[_vm._v("~"+_vm._s(item.timer.total_human))])]}},{key:"expand-item",fn:function(ref){
var item = ref.item;
return [(item.timer.summary.length)?_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},_vm._l((item.timer.summary),function(timer,index){return _c('v-col',{key:index,attrs:{"md":"4","sm":"6","xs":"12"}},[_c('TaskTimerBox',{attrs:{"item":timer,"task":item}})],1)}),1):_c('v-chip',{staticClass:"ma-1 fullwidth text-none",attrs:{"label":"","large":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-clock-alert-outline")]),_vm._v(" No Task Timers Found ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }